import React, {useRef, useState} from 'react'
import { Styling } from './styled.js'
import Play from '../../../images/play.inline.svg'

export default function Video({
    video, 
    addBottomColor, 
    bottomColor,
    darkLightMode,
    index,
    heading
}) {

    const vid = useRef();
    const [isPlaying, setIsPlaying] = useState(false)
    const handlePlayVideo = () => {
        if(isPlaying === false){
            vid.current.play()
            setIsPlaying(true)
        }else{
            vid.current.pause()
            setIsPlaying(false)
        }
    }

    return (
        <Styling id={`section_${index+1}`} className={addBottomColor === true ? 'add_bottom_color videoWrapper' : "videoWrapper"} bottomColor={bottomColor}  darkLightMode={darkLightMode}>
            {heading ? <h2 className="title">{heading}</h2> : ''}
            <div className="video-wrapper">
                <button className={isPlaying ? `play active` : `play`}   onClick={() => handlePlayVideo()}><Play /></button>
                <video playsInline ref={vid} src={video}></video>
            </div>
        </Styling>
    )
}
