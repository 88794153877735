import styled from 'styled-components'

const Styling = styled.div`
width:100%;
background:${(props) => props.darkLightMode === 'dark' || props.darkLightMode === undefined ? 'var(--black)' : 'var(--white)'};
color:${(props) => props.darkLightMode === 'dark' || props.darkLightMode === undefined ? 'var(--white)' : 'var(--black)'};
position: relative;
padding-bottom: 4rem;

    &.add_bottom_color{
        &:after{
        content:"";
        width:100%;
        height:50%;
        background:${(props) => props.bottomColor};
        position:absolute;
        bottom:0;
        left:0;
        }
    }

    .title{
    padding-top: 4rem;
    margin-bottom: 4rem;
    font-size: clamp(3rem,3vw,9rem);
    font-family: tomato--regular;
    letter-spacing: -0.09em;
    text-transform: uppercase;
    text-align: center;
    }

    .video-wrapper{
    width:100%;
    margin:0 auto;
    position: relative;

        @media only screen and (min-width: 768px) {
            width:80%;
        }

        .play{
        width: 100%;
        height:100%;
        position: absolute;
        top:0;
        left:0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
        ${({theme}) => theme.easing('0.5')}  
        cursor: pointer;
        border:0;

            &:hover{
            transform: scale(1.3);
            }

            &.active{
            transform: scale(3);
            opacity: 0;
            }

        }

        video{
        width: 100%;
        z-index: 10;
        position: relative;
        }

    }

`

export { Styling }