
import styled from 'styled-components'

const Styling = styled.div`

    .intro{
    background:var(--white);
    padding:10rem 2rem 6rem 2rem;

        @media only screen and (min-width: 350px) {
        padding:10rem 4rem 6rem 4rem;
        }

        @media only screen and (min-width: 1024px) {
        padding:16rem 8rem 10rem 8rem
        }


        .intro-lower{
        width: 100%;
        padding:2rem 0 0 0;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap:wrap;

            @media only screen and (min-width: 768px) {
            padding:4rem 0;
            }


            @media only screen and (min-width: 1024px) {
                padding:8rem 0;
            }

            .image-wrapper{
            width: 100%;
            margin-bottom: 4rem;
            display: flex;
            align-items: center;
            position: relative;
            justify-content: center;
            overflow: hidden;
            cursor: pointer;

                @media only screen and (min-width: 768px) {
                width:40%;
                margin-bottom: 0;
                }

                .gatsby-image-wrapper{
                height: 100%;
                }

                .videoWrapper{
                height:100%;
                    
                    .video-wrapper{
                    width: 100%;
                    height: 100%;

                        video{
                        height: 100%;
                        object-fit: cover;
                        }
                    }

                }

            }

            .text-wrapper{
            width: 100%;
            position: relative;
            /* display: flex;
            flex-wrap: wrap;
            justify-content: flex-start; */
            padding: 0;
            
            @media only screen and (min-width: 768px) {
                width:60%;
                padding: 0 0 0 4rem;
            }


            @media only screen and (min-width: 1024px) {
                padding: 0 10% 0 8rem;
            }

                ul{
                    
                    li{
                        margin-bottom: 1rem;
                        line-height: 2rem;
                        font-size: 1.5rem;

                        @media only screen and (min-width: 1024px) {
                        line-height: 1.5em;
                        font-size:clamp(1.7rem, 1vw, 2rem);
                        }
                    }

                }

                p{
                margin-bottom: 2rem;
                line-height: 2rem;
                font-size: 1.5rem;

                    @media only screen and (min-width: 1024px) {
                        line-height: 1.5em;
                        font-size:clamp(1.7rem, 1vw, 2rem);
                        
                        &:first-child{
                        font-size:clamp(1.7rem, 1.3vw, 2.8rem);
                        }
                    }

                    
        
                    &:last-child{
                    margin-bottom: 0;
                    }

                }

                .capsule_button{
                margin-top:2rem;
                }

            }

        }
    }

    .main-title{
    ${({theme}) => theme.tomato('regular')}  
    text-transform:uppercase;
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size:2.5rem;
    line-height:2rem;
    margin-bottom:2rem;

        @media only screen and (min-width: 350px) {
        font-size:3rem;
        line-height:2.6rem;
        letter-spacing: -0.2rem;        
        }

        @media only screen and (min-width: 420px) {
        font-size:4rem;
        line-height:3rem;
        letter-spacing: -0.3rem;
        }
        
        @media only screen and (min-width: 610px) {
        font-size:5rem;
        line-height:4rem;
        letter-spacing: -0.4rem;
        }

        @media only screen and (min-width: 768px) {
        margin-bottom: 4rem;
        }
        
        @media only screen and (min-width: 800px) {
        font-size:6rem;
        line-height:5rem;
        }

        @media only screen and (min-width: 1024px) {
        font-size:clamp(6rem, 8vw, 12rem);
        line-height: clamp(6rem/1.15, 8vw/1.15, 12rem/1.15);
        margin-bottom: clamp(6rem/2, 6vw/2, 20rem/2);
        }
    
        span{
        width: 100%;
        align-items: center;
        display: flex;
        }

        .arrow{
        width: 1em;
        height:1em;
        transform: scale(0.6) translateX(20%) translateY(10%);
        margin-right:1rem;

            path{
            fill:var(--black);
            }

        }
    }


    .courses{
    width:100%;
    background:var(--grey_light);
    position: relative;

        .programme{
        width: 100%;
        display: flex;

            .text,
            .image-wrapper{
            width: 50%;
            height:100%;
            }

            .image-wrapper{
                .gatsby-image-wrapper{
                width: 100%;
                }
            }

            .text{
            margin:auto;
            padding:0 10%;

                .inner_text{
                line-height: 1.5em;
                font-size:clamp(1.4rem, 1vw, 2rem);
                margin-bottom: 2rem;
                }

                .title{
                font-size:clamp(2.2rem, 3vw, 4rem);
                text-transform: uppercase;
                letter-spacing: -0.07em;
                margin-bottom: 2rem;
                ${({theme}) => theme.tomato('regular')}  
                }

            }

            &:nth-child(odd){
                .text{
                order:1;
                }
                .image-wrapper{
                order:2;
                }
            }
            &:nth-child(even){
                .text{
                order:2;
                }
                .image-wrapper{
                order:1;
                }
            }

        }

    }

`

export { Styling }