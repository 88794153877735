import React from 'react'
import { graphql } from 'gatsby'
import {Link} from 'gatsby'
import ArrowDown from '../../../images/arrow_right_black.inline.svg'
import {Styling} from '../../../styles/pages/programmes-dj.styled'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import parse from 'html-react-parser'
import Seo from '../../../components/global/seo'
import Video from '../../../components/reusable/Video'

export default function DjProgrammes(props) {
    return (
        <Styling>
          <Seo title={props.data.wpPage.seo.title} description={props.data.wpPage.seo.metaDesc} />
          <div className="intro">
              <h2 className="main-title"><span><ArrowDown className="arrow title-arrow"/> Degree</span> <span>Programme</span></h2>
              <div className="intro-lower">
                <div className="image-wrapper">
                  {
                    props?.data?.wpPage?.programme_archive_fields_dj_artist?.introImage?.localFile ? 
                      <GatsbyImage image={getImage(props?.data?.wpPage?.programme_archive_fields_dj_artist?.introImage?.localFile)} className="image" alt={'test'} /> 
                    : ""
                  }
                  {
                    props?.data?.wpPage?.programme_archive_fields_dj_artist?.introVideo ?
                      <Video video={props.data.wpPage.programme_archive_fields_dj_artist.introVideo} />
                    : ""
                  }
                </div>
                <div className="text-wrapper">
                  {parse(props.data.wpPage.programme_archive_fields_dj_artist.introText)}
                  <a href="https://waterbear.org.uk/courses/ba-hons-electronic-music-and-business/" rel="norefferer nofollow" target="_blank" className="capsule_button black">Learn more</a>
                </div>
              </div>
          </div>
          {/* <div className="courses">
            {props.data.courses.nodes.map((course, index) => {
              return(
                <article className="programme">
                  <div className="text">
                    <h2 className="title">{course.title}</h2>
                    <div className="inner_text">{parse(course.courseBuilder.briefDescription)}</div>
                    <Link className="capsule_button transparent dark" to={`/programmes/artist-development/${course.slug}/`}>View more</Link>
                  </div>
                  <div className="image-wrapper">
                    <GatsbyImage image={getImage(course.courseBuilder.courseThumbnailImage.localFile)} className="image" alt={'test'} />
                  </div>
                </article>
              )
            })}
          </div> */}
        </Styling>
    )
}

export const query = graphql`
  {
    wpPage(title: {eq: "Degree"}) {
        title
        seo{
          title
          metaDesc
        }
        programme_archive_fields_dj_artist {
          introText
          introVideo
          introImage{
            localFile{
              childImageSharp {
                gatsbyImageData(
                  width: 1500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
    }

  }
`